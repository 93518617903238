import { Timestamp } from "@/types";

export const formatTimestamp = (timestamp: Timestamp): string => {
  if (!timestamp._seconds) {
    return "Invalid timestamp";
  }

  const date = new Date(timestamp._seconds * 1000);

  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  return `${hours}:${minutes}`;
};

export const env = (key: string) => {
  const value = import.meta.env[key];
  if (!value) {
    console.error(`Env ${key} not found`);
    throw new Error(`Env ${key} not found`);
  }
  return value;
};
