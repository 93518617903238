import styles from "./LoadingSpinner.module.scss";

type LoadingSpinnerProps = {
  size?: number; // Size in pixels
};

export function LoadingSpinner({ size = 20 }: LoadingSpinnerProps) {
  const strokeWidth = size / 5; // Adjust the thickness relative to the size
  const radius = (size - strokeWidth) / 2; // Outer radius of the doughnut

  // Center of the circle (SVG)
  const cx = size / 2;
  const cy = size / 2;

  // The start position (top of the circle)
  const startX = cx;
  const startY = strokeWidth - size / 12; // Outer edge of the doughnut

  // The end position for the arc (right side of the doughnut)
  const endX = cx + radius;
  const endY = cy;

  // Adjusted arc path
  const arcPath = `M${startX} ${startY} A${radius} ${radius} 0 0 1 ${endX} ${endY}`;

  return (
    <svg
      className={styles.loader}
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        className={styles.spinnerBackground}
        cx={cx}
        cy={cy}
        r={radius}
        stroke="var(--wlcm-color-inactive-grey)"
        strokeWidth={strokeWidth}
        fill="none"
      />
      <path
        className={styles.spinnerPath}
        d={arcPath}
        stroke="var(--wlcm-color-dark-inactive-grey)"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        fill="none"
      />
    </svg>
  );
}
