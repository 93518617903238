export type Timestamp = {
  _seconds: number;
  _nanoseconds: number;
};

export type Building = {
  displayName: string;
  accessSystem?: AccessSystem;
};

export type Entrance = {
  entranceId: string;
  address: string;
  entranceName: {
    en: string;
    no: string;
  };
};

export type Company = {
  buildingTenantId: string;
  buildingTenantName: string;
  companyLogoImgUrl: string;
  floor?: string;
  visitMode: "enabled" | "limited" | "disabled";
  customLetInMessage?: string;
};

export type Person = {
  userId: string;
  firstName: string;
  lastName: string;
  language?: string;
};

export type Visitor = {
  phoneNumber: string;
  name: string;
  company?: string;
  message?: string;
};

export type PreRegistrationAttendee = {
  name: string;
  phone: string;
  email?: string;
  arrivedAt?: Timestamp;
};

export type PreRegistrationGroup = {
  id: string;
  hostId: string;
  hostName?: string;
  companyId: string;
  eventName: string;
  eventDescription?: string;
  startTime: Timestamp;
};

export enum AccessSystem {
  STARWATCH = "starwatch",
}
