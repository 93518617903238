import styles from "@/styles/RegisterFail.module.scss";
import { Button } from "@app-components";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { useLocation } from "wouter";
import {
  useRequestBodyStore,
  useVisitorStore,
  VisitStore,
  VisitType,
} from "@/lib/store";
import { useState } from "react";
import { BASE_URL } from "@/lib/constants";

export default function RegisterFail() {
  const { t, i18n } = useTranslation();
  const [isDisabled, setIsDisabled] = useState(false);
  const [isError, setIsError] = useState(false);
  const [, setLocation] = useLocation();
  const {
    visitType,
    hostPerson,
    hostGroup,
    preRegisteredPerson,
    setSelectedGroup,
  }: VisitStore = useVisitorStore();
  const { requestBody, resetWizard } = useRequestBodyStore();

  const hardRefreshApp = (devOverride = false) => {
    if (process.env.NODE_ENV !== "development" || devOverride) {
      i18n.changeLanguage("no");
      setLocation(`/?entranceId=${localStorage.getItem("entranceId")}`);
      window.location.reload();
    }
  };

  const tryAgain = async () => {
    setIsDisabled(true);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    };

    try {
      const response = await fetch(
        `${BASE_URL}/registerVisitor?visit-type=${visitType}${preRegisteredPerson ? "&user-was-pre-reg=true" : ""}`,
        requestOptions,
      );
      if (response.ok) {
        const resData = await response.json();
        if (hostGroup && resData.description)
          setSelectedGroup({
            ...hostGroup,
            eventDescription: resData.description,
          });
        resetWizard();
        setLocation("/registeredsuccess");
      }
    } catch (error) {
      setIsError(true);
      console.error(error);
    }
  };

  return (
    <div className={styles.container}>
      <motion.div
        initial={{ opacity: 0, y: 30 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 30 }}
        className={styles.mainText}
      >
        <h1>
          <strong>{t("Registration.Failed.Header")}</strong>
        </h1>
        <p>
          {t("Registration.Failed.Text", {
            hostFirstName:
              visitType === VisitType.PERSON
                ? hostPerson.firstName
                : t("Someone").toLowerCase(),
          })}
        </p>
        {isError ? (
          <p className={styles.failedAgain}>{t("Registration.Failed.Again")}</p>
        ) : (
          <Button dark large onClick={tryAgain} disabled={isDisabled}>
            {t("Registration.Failed.TryAgain")}
          </Button>
        )}
      </motion.div>
      <div className={styles.bottomText}>
        <p>{t("Registration.Failed.BigIssue")}</p>
        <Button dark large onClick={() => hardRefreshApp(true)}>
          {t("Registration.Failed.HardReload")}
        </Button>
      </div>
      <motion.div
        className={styles.countdownBar}
        initial={{ x: 0 }}
        animate={{ x: "-100%" }}
        transition={{ duration: 12 }}
        onAnimationComplete={() => hardRefreshApp()}
      ></motion.div>
    </div>
  );
}
