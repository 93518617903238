import { Button } from "@app-components";
import styles from "@/styles/FrontDeskButton.module.scss";
import { useTranslation } from "react-i18next";
import { useVisitorStore, VisitType } from "@/lib/store";
import { useLocation } from "wouter";
import { motion } from "framer-motion";

export default function FrontDeskButton() {
  const { t } = useTranslation();
  const [, setLocation] = useLocation();
  const { setVisitType } = useVisitorStore();

  const registration = () => {
    setVisitType(VisitType.COMPANY);
    setLocation("/registervisitor");
  };

  return (
    <motion.div
      className={styles.container}
      initial={{ y: 30, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.5, delay: 3 }}
    >
      <p>{t("CallFrontDesk.HelperText")}</p>
      <Button large secondary onClick={registration}>
        {t("CallFrontDesk.Button")}
      </Button>
    </motion.div>
  );
}
