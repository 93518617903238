import { ChangeEvent, FocusEvent, useEffect, useRef, useState } from "react";
import { InputField } from "../InputField";
import type { InputFieldProps } from "../InputField/InputField";
import styles from "./PhoneInput.module.scss";

const getFlagEmoji = (countryCode: string) =>
  countryCode
    .toUpperCase()
    .replace(/./g, (char) => String.fromCodePoint(127397 + char.charCodeAt(0)));

type CountryCode = {
  code: string;
  dialCode: string;
};

type PhoneInputProps = Omit<
  InputFieldProps,
  "type" | "prepend" | "onChange"
> & {
  countries?: CountryCode[];
  otherOptionText?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>, countryCode: string) => void;
};

export function PhoneInput({
  countries,
  value,
  otherOptionText = "Other",
  onChange,
  onBlur,
  ...props
}: PhoneInputProps) {
  const inputRef = useRef<HTMLInputElement>(null);

  const findMatchingCountryCode = (phone?: string) => {
    if (!phone || !countries || phone.length < 4) return "";
    // Sort country codes by length (longest first)
    const sortedCountries = [...countries].sort(
      (a, b) => b.dialCode.length - a.dialCode.length,
    );
    return (
      sortedCountries.find((c) => phone.startsWith(c.dialCode))?.dialCode || ""
    );
  };

  const [countryCode, setCountryCode] = useState(
    value ? findMatchingCountryCode(value) : "+47",
  );

  useEffect(() => {
    const detectedCode = findMatchingCountryCode(value);
    if (detectedCode && detectedCode !== countryCode) {
      setCountryCode(detectedCode);
    }
  }, [value, countries, countryCode]);

  const escapedCountryCode = countryCode.replace(/\+/g, "\\+");
  const phoneNumber = value?.replace(new RegExp(`^${escapedCountryCode}`), "");

  const triggerOnChange = (
    originalEvent: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    newValue: string,
    newCountryCode = countryCode,
  ) => {
    const modifiedEvent = Object.create(originalEvent);
    modifiedEvent.target = {
      ...originalEvent.target,
      value: newValue.replace(/(?!^)\+/g, ""), // replace all + except the first one
    };
    onChange?.(modifiedEvent, newCountryCode);
  };

  const triggerOnBlur = (
    originalEvent: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    newValue: string,
  ) => {
    if (!onBlur) return;
    // Manually create a blur event for the input field to trigger validation
    const syntheticEvent = {
      ...originalEvent,
      target: {
        ...inputRef.current,
        value: newValue.replace(/(?!^)\+/g, ""), // replace all + except the first one,
      }, // Redirect target to input field
      currentTarget: inputRef.current,
    } as FocusEvent<HTMLInputElement>;
    onBlur(syntheticEvent);
  };

  const handlePhoneChange = (e: ChangeEvent<HTMLInputElement>) => {
    let newPhone = e.target.value;
    let newCountryCode = countryCode;

    if (!newPhone) {
      triggerOnChange(e, "", "");
      return;
    }

    // Detect if the user is manually typing a country code
    if (newPhone.length >= 4 && newPhone.startsWith("+")) {
      const detectedCode = findMatchingCountryCode(newPhone);
      if (detectedCode) {
        setCountryCode(detectedCode);
        newCountryCode = detectedCode;
        newPhone = newPhone.replace(detectedCode, "");
      } else {
        setCountryCode("");
        newCountryCode = "";
      }
    }

    triggerOnChange(e, `${newCountryCode}${newPhone}`, newCountryCode);
  };

  const handleCountryChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const newCountryCode = e.target.value;
    const newValue = `${newCountryCode}${phoneNumber}`;
    setCountryCode(newCountryCode);
    if (!newCountryCode || phoneNumber) {
      triggerOnChange(e, newValue, newCountryCode);
    }
    triggerOnBlur(e, newValue);
  };

  useEffect(() => {
    if (value) {
      setCountryCode(findMatchingCountryCode(value));
    }
  }, [value]);

  return (
    <InputField
      {...props}
      ref={inputRef}
      type="tel"
      value={phoneNumber}
      onChange={handlePhoneChange}
      onBlur={(e) => triggerOnBlur(e, `${countryCode}${e.target.value}`)}
      prepend={
        countries?.length && (
          <select
            value={countryCode}
            onChange={handleCountryChange}
            onClick={(e) => e.stopPropagation()}
            className={`${styles.countryCodeSelect} ${props.dark ? styles.dark : ""}`}
          >
            {countries?.map((country) => (
              <option key={country.code} value={country.dialCode}>
                {getFlagEmoji(country.code)} {country.dialCode}
              </option>
            ))}
            <option value="">{otherOptionText}</option>
          </select>
        )
      }
    />
  );
}
