import { env } from "./utils";

type Environment = "dev" | "preview" | "prod";

export const MORE_INFO_URL = "www.welcomeworkdays.com";
export const NUMERIC_REGEX = /^[0-9+]+$/;
export const MESSAGE_REGEX = /^[\p{L}\p{N}\s!'\-&()?,."]*$/u;
export const NAME_REGEX = /^[\p{L}\s\-&.]*$/u;
export const COMPANY_REGEX = /^[\p{L}\p{N}\s\-&.]*$/u;
export const PHONE_REGEX = /^[+]([0-9]{3,15})$/;

const ENV_DOMAINS = {
  dev: `https://portal-${env("VITE_FB_PROJECT")}.web.app`,
  preview: `https://${env("VITE_REAL_ESTATE")}.welcomeworkdays.dev`,
  prod: `https://${env("VITE_REAL_ESTATE")}.welcomeworkdays.com`,
};
export const APP_DOMAIN = ENV_DOMAINS[env("VITE_ENVIRONMENT") as Environment];

export const BASE_URL = `${env("VITE_API_BASE")}/api`;

export const APPEAR_ANIMATION = {
  from: { opacity: 0, y: 30 },
  to: { opacity: 1, y: 0 },
};

export const SUPPORTED_PHONE_COUNTRY_CODES = [
  { code: "us", dialCode: "+1" }, // United States
  { code: "nl", dialCode: "+31" }, // Netherlands
  { code: "be", dialCode: "+32" }, // Belgium
  { code: "fr", dialCode: "+33" }, // France
  { code: "es", dialCode: "+34" }, // Spain
  { code: "it", dialCode: "+39" }, // Italy
  { code: "ch", dialCode: "+41" }, // Switzerland
  { code: "at", dialCode: "+43" }, // Austria
  { code: "gb", dialCode: "+44" }, // United Kingdom
  { code: "dk", dialCode: "+45" }, // Denmark
  { code: "se", dialCode: "+46" }, // Sweden
  { code: "no", dialCode: "+47" }, // Norway
  { code: "pl", dialCode: "+48" }, // Poland
  { code: "de", dialCode: "+49" }, // Germany
  { code: "pt", dialCode: "+351" }, // Portugal
  { code: "ie", dialCode: "+353" }, // Ireland
  { code: "is", dialCode: "+354" }, // Iceland
  { code: "fi", dialCode: "+358" }, // Finland
  { code: "cz", dialCode: "+420" }, // Czech Republic
];
