import { Icon } from "../Icon";
import styles from "./StatusText.module.scss";

type SuccessVariant = {
  success: true;
  fail?: never;
  warning?: never;
};
type FailVariant = {
  fail: true;
  success?: never;
  warning?: never;
};
type WarningVariant = {
  warning: true;
  success?: never;
  fail?: never;
};
type InfoVariant = {
  success?: never;
  fail?: never;
  warning?: never;
};
type Statuses = SuccessVariant | FailVariant | WarningVariant | InfoVariant;

type StatusTextProps = Statuses & {
  dark?: boolean;
  text: string;
};

export function StatusText({
  success,
  fail,
  warning,
  dark,
  text,
}: StatusTextProps) {
  return (
    <div className={`${styles.statusText} ${dark ? styles.dark : ""}`}>
      {success ? (
        <Icon className={styles.successIcon} name="check_circle" />
      ) : fail ? (
        <Icon className={styles.errorIcon} name="emergency" />
      ) : warning ? (
        <Icon className={styles.warningIcon} name="warning" />
      ) : (
        <Icon className={styles.infoIcon} name="info" />
      )}
      <p
        className={`${styles.statusText} ${success ? styles.success : ""} ${fail ? styles.fail : ""}`}
      >
        {text}
      </p>
    </div>
  );
}
