"use client";
import { useUsers } from "@/hooks/getUsers";
import styles from "@/styles/Company.module.scss";
import PersonSelector from "@/components/PersonSelector";
import Header from "@/components/Header";
import { useVisitorStore, VisitType } from "@/lib/store";
import { useTranslation } from "react-i18next";
import { Button, Icon } from "@app-components";
import { useLocation } from "wouter";
import WelcomeBadge from "./WelcomeBadge";
import { AnimatePresence, motion } from "framer-motion";
import { usePreRegistrations } from "@/hooks/getPreRegistrations";
import Loader from "./Loader";
import { useEffect, useState } from "react";
import { formatTimestamp } from "@/lib/utils";
import { PreRegistrationGroup } from "@/types";

const animateFrom = { opacity: 0, y: 30 };
const animateTo = { opacity: 1, y: 0 };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function Company({ params }: any) {
  const companyId = params.id;
  const entranceId = localStorage.getItem("entranceId");
  const { t, i18n } = useTranslation();
  const [, setLocation] = useLocation();
  const { hostCompany, resetWizard, setVisitType, setSelectedGroup } =
    useVisitorStore();
  const {
    isLoading: isLoadingUsers,
    isError: isErrorUsers,
    data: users,
  } = useUsers(companyId);
  const { isLoading: isLoadingPreRegistrations, data: preRegisteredGroups } =
    usePreRegistrations(companyId);
  const [preRegDismissed, setPreRegDismissed] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const redirectToHome = (devOverride = false) => {
    if (process.env.NODE_ENV !== "development" || devOverride) {
      i18n.changeLanguage("no");
      setLocation(`/?entranceId=${entranceId}`);
      resetWizard();
    }
  };

  const selectPreRegGroup = (group: PreRegistrationGroup) => {
    setVisitType(VisitType.PRE_REG);
    setSelectedGroup(group);
    setLocation("/registervisitor");
  };

  // Only show loader if it takes more than 500ms to load to avoid flashing screens
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (isLoadingPreRegistrations) {
      timeout = setTimeout(() => {
        setShowLoader(true);
      }, 500);
    } else {
      setShowLoader(false);
    }
    return () => clearTimeout(timeout);
  }, [isLoadingPreRegistrations]);

  if (!hostCompany || isErrorUsers) throw new Error("Crucial data missing");

  return (
    <main className={styles.main}>
      <Header />
      <AnimatePresence mode="wait">
        {showLoader ? (
          <motion.div
            key="loader"
            initial={animateFrom}
            animate={animateTo}
            exit={animateFrom}
            style={{ height: "100vh" }}
          >
            <div className="center">
              <Loader />
            </div>
          </motion.div>
        ) : hostCompany.floor && hostCompany.visitMode === "limited" ? (
          <motion.div
            key="visit-mode-limited"
            initial={animateFrom}
            animate={animateTo}
            exit={animateFrom}
            className={styles.visitDisabledContainer}
          >
            <div>
              <div className={styles.textContainer}>
                <h2>{t("StaticPage.Header", { floor: hostCompany.floor })}</h2>
                <p>
                  {t("StaticPage.Body", {
                    companyName: hostCompany.buildingTenantName,
                    floor: hostCompany.floor,
                  })}
                </p>
              </div>
              <Button success large onClick={() => redirectToHome(true)}>
                {t("Registration.Success.Finish")}
              </Button>
            </div>
            <WelcomeBadge />
            <motion.div
              className={styles.countdownBar}
              initial={{ x: 0 }}
              animate={{ x: "-100%" }}
              transition={{ duration: 12 }} // duration is in seconds
              onAnimationComplete={() => redirectToHome()}
            ></motion.div>
          </motion.div>
        ) : preRegisteredGroups?.length && !preRegDismissed ? (
          <motion.div
            key="pre-registration"
            initial={animateFrom}
            animate={animateTo}
            exit={animateFrom}
            className={styles.preRegContainer}
          >
            <h2>{t("PreRegistration.Header")}</h2>
            <div className={styles.dismissButton}>
              <Button size="lg" onClick={() => setPreRegDismissed(true)}>
                {t("PreRegistration.Decline")}
              </Button>
            </div>
            {preRegisteredGroups.map((group) => (
              <article
                key={group.id}
                className={styles.preRegCard}
                onClick={() => selectPreRegGroup(group)}
              >
                <div>
                  <h3>{group.eventName}</h3>
                  <p>
                    {t("PreRegistration.Today")},{" "}
                    {formatTimestamp(group.startTime)}
                    {group.hostName && (
                      <>
                        <span aria-hidden>|</span>{" "}
                        {t("PreRegistration.HostedBy")} {group.hostName}
                      </>
                    )}
                  </p>
                </div>
                <Icon name="arrow_forward" />
              </article>
            ))}
          </motion.div>
        ) : (
          !isLoadingPreRegistrations && (
            <motion.div
              key="person-selector"
              initial={animateFrom}
              animate={animateTo}
              exit={animateFrom}
              className={styles.personsContainer}
            >
              <PersonSelector users={users} isLoading={isLoadingUsers} />
            </motion.div>
          )
        )}
      </AnimatePresence>
    </main>
  );
}
